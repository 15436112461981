<template>
  <v-footer id="home-footer" color="black" dark min-height="72">
    <v-container>
      <v-row>
        <!-- <v-col cols="12" md="6">
          <div
            class="d-flex flex-wrap justify-md-start justify-center justify-md-none"
          >
            <template v-for="(s, i) in social">
              <a
                :key="s"
                class="white--text pa-1 pa-md-0"
                href="#"
                v-text="s"
              />

              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col> -->

        <v-col
          class="text-center text-md-left text-body-2"
          cols="12"
          md="9"
          lg="9"
        >
          Copyright &copy; {{ new Date().getFullYear() }} AWM Research &
          Consulting Ltd, 124 City Road, EC1V 2NX, London (UK) - Company n.
          08394312 - VAT GB407641800
        </v-col>
        <v-col class="text-center text-md-right" cols="12" md="3" lg="3">
          <v-icon small class="mr-2">mdi-cellphone</v-icon>

          <span>Call us +44 (0) 7462 816655</span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",

  data: () => ({
    social: ["Facebook", "Twitter", "Instagram", "Linkedin"],
  }),
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
